@keyframes slidein {
  from {
    transform: translateX(0px);
  }

  to {
    transform: translateX(calc(0px - 100vw - 150%));
  }
}

.sliding_image1 {
  animation: 55s infinite linear slidein;
}

.sliding_image2 {
  animation: 45s infinite linear slidein;
}

.sliding_image3 {
  animation: 35s infinite linear slidein;
}

.sliding_image4 {
  animation: 65s infinite linear slidein;
}
