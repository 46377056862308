@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.cdnfonts.com/css/d-din');

:root {
  --propulse-blue: #2d3afe;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Raleway';
  src:
    local('Raleway'),
    url('./fonts/Raleway/Raleway-Regular.ttf') format('truetype');
  font-family: 'Raleway';
  src:
    local('Raleway'),
    url('./fonts/Raleway/Raleway-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway Semi Bold';
  src:
    local('RalewaySemiBold'),
    url('./fonts/Raleway/Raleway-SemiBold.ttf') format('truetype');
  font-family: 'Raleway Semi Bold';
  src:
    local('RalewaySemiBold'),
    url('./fonts/Raleway/Raleway-SemiBold.ttf') format('truetype');
}
