/* Header style */
.Header {
  display: flex;
  flex-direction: row;
  height: 110px;
  min-width: fit-content;
  background-color: white;
  padding-bottom: 10px;
}

.imgModeHeader {
  display: flex;
  flex-direction: row;
  height: 110px;
  min-width: fit-content;
  /* background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 35%); */
  padding-bottom: 10px;
}

.LogoDiv {
  display: flex;
  margin-right: 10px;
  min-width: 140px;
  width: 20vw;
  justify-content: flex-end;
  align-items: flex-end;
}

.HeaderLogo {
  width: 130px;
  height: auto;
  fill: #0d0ebd;
}

/*Navbar style */

.Navbar {
  align-self: end;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.NavbarDiv {
  display: flex;
  flex-grow: 1;
  justify-content: right;
  margin-right: 8vw;
}

.NavbarLink {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 15px;
  display: inline;
  color: #0d0ebd; /*Ikke egt riktig farge, få riktig fargekode av Thanathon*/
  /* letter-spacing: 1.5px; */
  margin: 10px;
}

.nav-link.activated p {
  color: #04053f;
}

a {
  text-decoration: none;
  color: inherit;
}
