.Main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: url(../assets/images/backgrounds/background1.png) no-repeat center
    center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
}

.Sub {
  width: 70%;
  padding-top: 20px;
  min-height: 100vh;
  overflow: hidden;
}

.Content {
  margin-bottom: 400px;
}

.pp-title {
  font-size: 40px;
  color: #0035f7;
  text-align: left;
}

.standardTitle {
  font-weight: bolder;
}

.basicButton {
  height: 5vw;
  vertical-align: middle;
  padding: 0 2vw;
  font-size: large;
  font-weight: bold;
  color: rgb(88, 88, 88);
  background: none;
  border-radius: 1vw;
  border: 2px solid rgb(88, 88, 88);
  cursor: pointer;
}
