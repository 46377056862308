.MemberList {
  min-width: 1000px;
  width: 100%;
}

.GroupNavbar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  width: 90%;
  margin: auto;
}

.GroupNavlink {
  border: 2px solid #0d0ebd;
  border-radius: 10px;
  margin: 10px;
}

.GroupNavlink:hover {
  background-color: #0d0ebd;
  color: white;
}

.GroupNavlink.activated {
  background-color: #0d0ebd;
  color: white;
}

.GroupNavlink p {
  margin: 5px 20px;
  font-size: 20px;
}

.GroupContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 95%;
  height: 100%;
  margin: auto;
  flex-wrap: wrap;
}

.GroupTitle {
  font-size: 40px;
}

.Member {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;
  width: 300px;
  background-color: #f5f5f5;
  margin: 10px;
  border-radius: 20px;
}

.Member img {
  border-radius: 50%;
  object-fit: cover;
}
