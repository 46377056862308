.SoMeLine {
  display: flex;
  flex-direction: row;
  width: fit-content;
  height: fit-content;
}

.Icon {
  margin: 2px;
}
