.Footer {
  display: flex;
  background-color: white;
  height: 100px;
  width: 100%;
  justify-content: center;
  padding-top: 20px;
  border-top: 1px solid #dce4ee;
  font-family: 'Raleway Semi Bold', 'sans-serif';
  font-size: 18px;
}

.FooterContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  max-width: 1000px;
}
