.animate-slide-in {
  opacity: 0;
  transform: translateY(50%);
  transition:
    opacity 1s ease-out,
    transform 1s ease-out;
}

.animate-slide-right {
  opacity: 0;
  transform: translateX(-50%);
  transition:
    opacity 1s ease-out,
    transform 1s ease-out;
}

.animate-slide-left {
  opacity: 0;
  transform: translateX(50%);
  transition:
    opacity 1s ease-out,
    transform 1s ease-out;
}

.animate {
  opacity: 1;
  transform: translateY(0%);
  transform: translateX(0%);
  transition:
    opacity 1s ease-out,
    transform 1s ease-out;
}

.animate-scrolldown {
  animation: scrolldown 3s infinite;
  transition: height 1s ease-in;
}

.animate-scrolldown:hover {
  transition: height 1s;
  animation: scrolldown 1s reverse;
  animation-play-state: paused;
  cursor: pointer;
}

@keyframes sd {
  from {
    height: 22%;
  }

  to {
    height: 0%;
  }
}

@keyframes scrolldown {
  0% {
    opacity: 1;
  }

  20% {
    opacity: 1;
    height: 22%;
  }

  60% {
    height: 5%;
    opacity: 1;
  }

  80% {
    opacity: 0;
    height: 5%;
  }

  100% {
    opacity: 0;
  }
}
